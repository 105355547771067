@import '~styles/combined-variables';

.DateRangePicker {
  &--inputGroup {
    min-width: 325px;
    cursor: pointer;

    &.disabled {
      pointer-events: none;

      .form-control[readonly] {
        background-color: $gray-200;
        opacity: 1;
      }
    }

    .form-control[readonly] {
      background-color: $white;
      box-shadow: none;
    }
  }
}
