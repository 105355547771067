@import '~styles/combined-variables';

.FormHeader {
  &-title {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
  }

  &-subtitle {
    color: $gray-500;
  }

  .btn {
    margin-left: 0.5rem;
  }
}

