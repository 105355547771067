.SidePanel {
  & > :first-child {
    & > :nth-child(2) {
      & > :first-child {
        cursor: auto !important;
      }
      & > :nth-child(2) {
        overflow: visible !important;
      }
    }
  }
}
