.FormattedTable {
  &-toolbarContainer {
    margin: 1rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &-leftToolbar {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }
  &-rightToolbarContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: baseline;
  }
  &-rightToolbar {
    margin-right: 1rem;
  }
}

.NoDataMessage {
  order: 2;
  text-align: center;
  padding: 100px 0;
}

.pagination-bottom {
  order: 3;
}
