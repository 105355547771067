@import "~styles/combined-variables";

.LabelLink {
  color: $blue;
  text-decoration: none;
  background-color: transparent;
  outline: none;

  &:hover {
    color: darken($blue,15%);
    cursor: pointer;
  }
}
