@keyframes placeHolderShimmer {
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
}

.Placeholder {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #F6F6F6;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 800px 104px;
  border-radius: 5px;
  position: relative;
}
