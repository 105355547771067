@import "~styles/combined-variables";

.badge {
  padding: 0.25rem 0.3125rem;
  border-radius: 0.25rem;
}

.badge-pill {
 border-radius: 10rem;
}

.badge-light {
  color: $gray-400;
}

.badge-secondary {
  color: $gray-700;
}

.badge-outline-secondary {
  background-color: $white;
  border:1px solid $gray-300;
  color: $gray-500;
  font-weight: 600;
}

.badge.badge-outline {
  background-color: $white;
}

@each $color, $value in $theme-colors {
  .badge-#{$color}.badge-outline {
    border: 1px solid lighten($value, 20%);
    color: darken($value, 20%);
  }
}
