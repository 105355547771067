@import "~nodemodules/react-table/react-table.css";

.ReactTable {
  border:none;
  overflow: hidden;

  .rt-table {
    order: 1;
    overflow: auto;
  }

  .edit-dropdown {
    overflow: visible !important;

    .btn {
      padding: 0.125rem 0.5rem;
      font-size: 0.875rem;
      border:1px solid transparent;
      color: $gray-400;

      &:hover {
        border-color: $blue;
      }
    }

    .dropdown {
      position: initial;
    }
  }

  .rt-thead {
    &.-header, &.-headerGroups {
      background-color: $gray-100;

      .rt-th.collapsed-title {
        display: flex;
        background: $gray-100;
        border-top: 1px solid $gray-100;
        border-bottom: 1px solid $gray-100;
        align-items: center;
        justify-content: center;
        writing-mode: vertical-rl;
        line-height: 1.1em;
        text-align: left;
        padding-top: 0px;
        padding-bottom: 4px;
          &:after {
            // hide sort arrow in collapsed header
            display: none;
          }
      }

      .rt-tr {
        background-color: inherit;
      }

      .rt-th {
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        border-right: 0;
        padding: 8px 10px;
        line-height: 1.1;
        min-height: 44px;
        background-color: inherit;

        font-weight: 600;
        color: $gray-800;
        font-size: 0.75rem;
        overflow: visible;

        &.-sort-desc, &.-sort-asc {
          box-shadow: none;

          &:after {
            visibility: visible;
            font-size: 0.5rem;
            color: $gray-800;
          }
        }
      }
    }

    &.-header {
      box-shadow: none;
    }

    &.-headerGroups, &.-headerGroups .rt-th {
      border-bottom: none;
    }

    & .rt-resizable-header-content {
      width: 100%;
    }
  }

  .rt-tbody {
    background-color: $white;

    // fix the table row height change on dropdown menu
    overflow: visible;

    .rt-expandable {
      cursor: inherit;
    }

    .rt-tr {
      background-color: inherit;
    }
    .rt-tr.selected {
      background-color: #DFEDFC !important;
    }

    .rt-tr.disabled {
      background-color: #eee !important;
    }

    .rt-td {
      position: relative;
      border-right: 0;
      padding: 8px 10px;
      line-height: 1.2;
      font-size: $font-size-base;
      background-color: inherit;
      border-bottom: 1px solid #edebf0; //$border-color;
      &.collapsed {
        background-color: $gray-100;
        border-bottom: 1px solid $gray-100;
      }
    }

    .rt-td.pivot-level-2 {
        font-weight: bold;
        background-color: $gray-100;
    }

    .rt-td.pivot-level-3 {
      font-weight: bold;
      background-color: $gray-100;
    }

    .rt-td.pivot-level-4 {
      font-weight: bold;
      background-color: $gray-100;
    }

    .rt-tr-group {
      background-color: inherit;
      border-bottom: none;
    }
  }

  &.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background: darken($gray-100, 1%);

    .edit-dropdown .btn {
      border-color: $gray-400;
      color: $gray-600;

      &:hover {
        color: $blue;
        background: $white;
        border-color: lighten($blue, 30%);
      }
    }
  }

  &.-verticalBorders {
      .rt-th, .rt-td {
        border-right: 1px solid $border-color !important;
        &.last-sticky-column {
          border-right-width: 2px !important;
        }
      }

      .rt-th:last-child, .rt-td:last-child {
        border-right: 0 !important;
      }

  }

  .cell-align-right {
    justify-content: flex-end;
    text-align: right;
  }

  .cell-align-left {
    justify-content: flex-start;
    text-align: left;
  }

  .cell-align-center {
    justify-content: center;
  }

  .-pagination {
    box-shadow: none;
    padding: 10px 0;
  }

  &.-striped {
    .rt-tr {
      &.-odd {
        background-color: #ffffff;
      }

      &.-even {
        background-color: $gray-000;
      }
    }
  }
}

// Moved from AdvertiserTable.scss that was removed in beb3c649988d2538f41289bf1e40c75e5a932a5f
// TODO merge with above
.ReactTable {
  .rt-td {
    display: flex;
    align-items: center;
  }

  .rt-tr {
    min-height: 46px;
  }

  .rt-th {
    white-space: normal !important;

    display: flex;
    align-items: center;

    &.-sort-asc {
      &:after {
        margin-left: 0.5em;
        content: "▲";
        color: #ccc;
      }
    }
    &.-sort-desc {
      &:after {
        margin-left: 0.5em;
        content: "▼";
        color: #ccc;
      }
    }
  }
}
