@import '~styles/combined-variables';

label.Switch {
  margin-bottom: 0;
}

.Switch {
  position: relative !important;
  display: inline-block;
  vertical-align: top;
  width: 40px;
  height: 24px;
  background-color: transparent;
  cursor: pointer;

  &.checked {
    .Switch-handle {
      left: 19px;
    }

    .Switch-label {
      background-color: map_get($theme-colors, primary);
    }
  }

  &.disabled {
    cursor: default;

    .Switch-label {
      background-color: $gray-200 !important;
    }

    &.checked .Switch-label {
      background-color: $gray-400 !important;
    }
  }
}

.Switch-input {
  position: absolute !important;
  top: 0;
  left: 0;
  opacity: 0;
}

.Switch-label {
  position: relative;
  display: block;
  height: inherit;
  width: 100%;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: $gray-300;
  border-radius: 20px;
  transition: opacity .15s ease-out, background .15s ease-out;
}

.Switch-handle {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 3px;
  left: 3px;
  background: $white;
  border-radius: 20px;
  transition: left .15s ease-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

// FormSwitch sizes
.switch-sm {
  width: 30px;
  height: 18px;

  .Switch-handle {
    width: 14px;
    height: 14px;
    top: 2px;
  }
  
  &.checked {
    .Switch-handle {
      left: 13px;
    }
  }
}
