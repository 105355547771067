@import "~styles/combined-variables";

.sticked.rt-thead {
  &.-header, &.-headerGroups {
    position: relative;
    z-index: 2;
  }
  &.-header {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
    border-bottom-color: $gray-300;
  }
}
