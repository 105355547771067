.app-header.navbar .navbar-brand {
    display: inline-block;
    width: 120px;
    height: 60px;
    padding: 1rem  0 0;
    margin-right: 0;
    background: none;
    border: none;
}

.navbar-toggler {
  padding: 0.25rem 1rem;
}

