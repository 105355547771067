@import "~styles/combined-variables";

.UploadInput {
  &-dropzone {
    cursor: pointer;
    border-width: 2px !important;
    border-style: dashed !important;

    &--active {
      border-color: $blue !important;
      cursor: copy;
    }
  }
}

