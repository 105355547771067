.ControlledCard, .CreativeCard {
    .ControlledCard {
        border-top: 1px solid $gray-300 !important;
        background-color: $white;

        .card-header {
            background-color: $white;
        }
    }
}

.card {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: none;
    margin-bottom: 1.5rem;
}
