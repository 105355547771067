@import '~styles/combined-variables';

.RoleEditList-headerRow {
  border-bottom: 2px solid $border-color;

  > div {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
}

.RoleEditList-headerTitle {
  font-weight: bold;
}

.RoleEditList-groupRow {
  padding: 0.5rem 0;
}

.RoleEditList-row {
  padding: 0.3rem 0;
  border-bottom: 1px solid $border-color;

  .form-check-input {
    margin-left: -0.25rem;
  }
}

.RoleEditList-expandArrow {
  &:focus {
    outline: none;
  }
}
