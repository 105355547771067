@charset "UTF-8";

@font-face {
  font-family: "deep-media";
  src:url("fonts/deep-media.eot");
  src:url("fonts/deep-media.eot?#iefix") format("embedded-opentype"),
  url("fonts/deep-media.woff") format("woff"),
  url("fonts/deep-media.ttf") format("truetype"),
  url("fonts/deep-media.svg#deep-media") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "deep-media" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="dm-"]:before,
[class*=" dm-"]:before {
  font-family: "deep-media" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dm-cog:before {
  content: "\61";
}
.dm-bid:before {
  content: "\62";
}
.dm-browser:before {
  content: "\63";
}
.dm-chat:before {
  content: "\64";
}
.dm-dashboard:before {
  content: "\65";
}
.dm-globe:before {
  content: "\66";
}
.dm-image:before {
  content: "\67";
}
.dm-map-marker:before {
  content: "\68";
}
.dm-keyword:before {
  content: "\69";
}
.dm-layers:before {
  content: "\6a";
}
.dm-linechart:before {
  content: "\6b";
}
.dm-strategy:before {
  content: "\6d";
}
.dm-users:before {
  content: "\6e";
}
.dm-units:before {
  content: "\6f";
}
.dm-chevron-right-circle:before {
  content: "\70";
}
.dm-chevron-right-circle-solid:before {
  content: "\71";
}
.dm-globe-americas:before {
  content: "\72";
}
.dm-desktop-tablet:before {
  content: "\73";
}
.dm-drill-down:before {
  content: "\6c";
}
.dm-options:before {
  content: "\74";
}
.dm-piechart:before {
  content: "\75";
}
.dm-score-insp:before {
  content: "\76";
}
