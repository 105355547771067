.sidebar-fixed .sidebar .sidebar-nav {
  height: calc(100vh - 60px - 20px - 60px);
}

.sidebar-minimized {
  .PlatformLogo {
    display: none;
  }
}

.sidebar {
  font-size: .875rem;
  background-color: transparent;
  padding: 0.75rem 0;

  .nav {
    > .nav-dropdown {
        .nav-dropdown-items {
          > .nav-separator {
            margin: 0 0 0 3rem;
            background: $gray-400;
            height: 1px;
          }

          > .nav-item {
              > .nav-link {
                color: $gray-800;
                border-radius: 0 4px 4px 0;
                padding: 0.4rem 0.3rem 0.4rem 3.2rem;

                &:hover{
                  background: $gray-200;
                }

                &.active {
                  font-weight: 600;
                  color: $blue;
                  background: #DFEDFC;
                  border-radius: 0 4px 4px 0;
                }
              }
          }
        }
    }

    > .nav-item {
      border-left: none;

      &.nav-dropdown {
        &.open {
          > .nav-link {
            color: $gray-900;
            background: $white;
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) inset;

            i {
              color: $blue;
            }
          }
        }
      }

      > .nav-link {
          font-weight: 500;
          color: $gray-900;
          text-transform: capitalize;
          margin: .125rem 0;
          border-radius: 0 4px 4px 0;
          padding: 0.75rem 1rem 0.75rem 1.25rem;

          i {
              color: $gray-500;
              font-size: 1rem;
              vertical-align: middle;
              margin-right: 0.875rem;
              line-height: 1;
            }

          &:hover {
            color: $gray-900;
            background: none;

            i {
              color: $gray-600;
            }
          }

          &.active {
            color: $gray-900;
            background: $white;
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) inset;
          }
      }

      &.open, &:hover {
        border-left: none;
      }
    }

    .nav-item {
      &.nav-dropdown {
        &.open {
          background: transparent;

          > ul {
            padding: 0.5rem 0 0;
          }
        }
      }

      .nav-link .nav-dropdown-arrow {
        color: $gray-400;
      }
    }
  }
}

@media (min-width: 992px) {
  .sidebar-minimized {
    .sidebar {
      .nav {
        .nav-item {
          &:hover{
            background: $white;
          }

          &.nav-dropdown {
            &.open {
              background: transparent;
            }
          }

          ul {
            background: $white;
            box-shadow: 2px 2px 6px rgba(0,0,0,.1);
            border-radius: 0 0 4px 4px;
          }

          .nav-link {
            margin: .125rem 0;

            i {
              font-size: 1rem;
              position: relative;
              margin: 0 1.25rem 0 0 !important;
            }
          }
        }

        > .nav-dropdown {
          .nav-dropdown-items {
            > .nav-item {
              > .nav-link {
                padding: .4rem .3rem .4rem 1.25rem !important
              }
            }
          }
        }
      }
    }
  }
}

.sidebar .sidebar-nav::-webkit-scrollbar {
  width: 0;
  height: 0;
}
