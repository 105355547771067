@import '~styles/combined-variables';

.ControlledCard {
  &-title {
    font-weight: 600;
    font-size: 0.875rem;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  .card-header {
    background-color: $white;
  }
  .card-actions {
    >:focus {
      outline: none;
    }
  }
}
