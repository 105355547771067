@import '~styles/combined-variables';

.DateRangePickerPopover {
  .popover {
    max-width: none !important;
    padding: 0.5rem;
    margin-right: 10px;

    @media (min-width: 992px) {
      margin-right: 33px;
    }
  }

  &--sidebar {
    display: flex;
    flex-direction: column;
    border-left: 1px solid $border-color;
    margin-left: 10px;
    padding-left: 10px;
  }

  &--presets {
    padding: 0;
    font-size: $font-size-base;
    list-style: none;

    > li {
      padding: 0.5rem 1rem;
      cursor: pointer;

      &:hover {
        background-color: $gray-100;
      }

      &.active {
        background-color: $gray-100;
      }
    }
  }

  &--actions {
    border-top: 1px solid $border-color;
    margin-top: 10px;
    padding-top: 10px;
  }

  .DateRangePickerPopover-noDateContainer {
    font-size: $font-size-base;

    input {
      margin: 0;
    }
  }
}
