.pagination-datatables, .pagination {
  li {
    @extend .page-item;

    a {
      @extend .page-link;
    }
  }
}

.label-pill {
  border-radius: 1rem !important;
}

// temp fix for Vue-Strap

// Open state for the dropdown
.open, .show {
  // Show the menu
  > .dropdown-menu {
    display: block;
  }

  // Remove the outline when :focus is triggered
  > a {
    outline: 0;
  }
}

//
.modal-open .modal {
  display: block;
}

// navbar dropdown fix
.navbar .dropdown-toggle {
  @extend .nav-link;

  .img-avatar {
    height: $navbar-height - 20px;
    margin: 0 10px;
  }
}

.card-block {
  @extend .card-body;
}

.sortable-select-helper {
  z-index: 99999999; // so that draggable items would be visible
}
