.custom-tabs {
  .tab-pane {
    padding-left: 0;
    padding-right: 0;
  }

  .tab-content {
    background-color: transparent;
    border-width: 0;
  }

  .nav-tabs {
    border-bottom-color: $gray-300;

    .nav-link {
      background: none;
      border:none;
      padding: 0.75rem 0.125rem 1rem;
      margin-right: 1.25rem;
      position: relative;
      transition:color .2s ease-out;
      font-size: 0.875rem;
      font-weight: 400;
      color: $gray-700;

      &:after {
        content: '';
        display: block;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        height: 3px;
        background-color: transparent;
        transition: background-color .2s ease-out;
      }

      &:hover {
        color: $gray-900;

        &:after {
          background-color: $gray-800;
        }
      }

      &.active {
        color: $blue;
        font-weight: 500;

        &:after {
          background-color: map_get($theme-colors, primary);
        }

        &:focus {
          background: none;
        }
      }
    }
  }
}
