@import '~styles/combined-variables';

.RecursiveTable {
    &-header {
        border-top: 1px solid $gray-100;
    }

    &-expandBtn {
        transition: transform 100ms;
        padding: 0.5rem;
        cursor: pointer;
        &--expanded {
            transform: rotate(90deg);
        }
    }

    .mb-4 {
        margin-bottom: 0 !important;
    }
}

.RecursiveTable.ReactTable {
    .rt-tbody {
        .rt-tr-group {
            border-bottom: 1px solid $gray-200;
         }
    }
    .rt-table {
        overflow: auto;
    }
}


