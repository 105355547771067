@import '~../../../scss/combined-variables';

.user-button {
    &.btn.btn-secondary:focus, &.btn.btn-secondary:active {
        box-shadow: none !important;
    }

    &.btn {
        font-family: $font-family-base;
    }

    &__initials {
        display: inline-block;
        border-radius: 50%;
        background-color: map_get($theme-colors, primary);
        color: $white;
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        text-align: center;
    }
}


