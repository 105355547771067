@import '~styles/combined-variables';

.CardForm.card {
  border: none;
  border-radius: 0;
  margin-bottom: 0;

  >.card-header {
    background-color: $white;
    box-shadow: 0 0.15rem 0.3rem rgba(0,0,0,.05);
    position: relative;
    z-index: 1;
  }

  >.card-body {
    background-color: $white;
    overflow: auto;
  }
}
.CardForm-submit {
  margin: -0.4rem 0 0 0.5rem;
}
