.PageHeader-title {
  /* Remove browser defaults */
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 300;
  font-size: 1.375rem;
  text-transform: capitalize;
}
