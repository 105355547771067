@import '~styles/combined-variables';

.pl-invalid-feedback.invalid-feedback {
  display: block;
}

.ReactCodeMirror.form-control {
  height: auto !important;
}

div.ReactCodeMirror--disabled {
  background-color: $gray-200;

  .CodeMirror {
    background-color: $gray-200;
  }

  .CodeMirror-lines {
    cursor: default;
  }
}
