@import "~styles/combined-variables";

.ReactTable-placeholder {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  pointer-events: all;
}

.ReactTable-placeholder-inner {
  position: absolute;
  background: white;
  width: 100%;
  height: 100%;

  .rt-tr:hover {
    background: white !important;
  }

  .rt-tr-group {
    &:first-child {
      border-top: 1px solid $border-color;
    }
    &:last-child {
      border-bottom: 1px solid $border-color !important;
    }
  }

}
