@import '~styles/combined-variables';

.DayPicker {
  font-size: 0.8125rem;
}

.DayPicker-Caption > div {
  font-size: $font-size-base;
  text-align: center;
}

.DayPicker-NavButton--prev {
  left: 1.3rem;
  margin-top: -1px;
}

.DayPicker-NavButton--next {
  right: 1.3rem;
  margin-top: -1px;
}

.DayPicker-Day {
  margin: 1px 0;
  border-radius: 0 !important;

  &--start:not(.DayPicker-Day--outside),
  &--end:not(.DayPicker-Day--outside) {
    background-color: map-get($theme-colors, primary) !important;
    color: $white;
  }

  &--outside {
    background-color: $white;
  }

  &--range:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: $gray-100 !important;
    color: $black;
    border-radius: 0 !important;
  }

  &--start:not(.DayPicker-Day--outside) {
    border-radius: 3px 0 0 3px !important;
  }

  &--end:not(.DayPicker-Day--outside) {
    border-radius: 0 3px 3px 0 !important;
  }
}
