@import '~styles/combined-variables';

.pl-tooltip {
  .tooltip.show {
    opacity: 1 !important;
  }
  .tooltip-inner {
    border: 1px solid $gray-300;
    border-radius: calc(0.3rem - 1px);
    min-width: 9.375rem;
    background: $white;
    opacity: 1;
    color: $black;
    text-align: left;
    padding: 0;
    max-width: 30rem;
    &-body {
      padding: 0.75rem 0;
    }
    &-footer {
      border-top: 1px solid $gray-300;
      padding: 0.75rem 0;
    }
  }
  &.bs-tooltip-right .arrow:before {
    border-right-color: $gray-300 !important;
  }
  &.bs-tooltip-left .arrow:before {
    border-left-color: $gray-300 !important;
  }
  &.bs-tooltip-top .arrow:before {
    border-top-color: $gray-300 !important;
  }
  &.bs-tooltip-bottom .arrow:before {
    border-bottom-color: $gray-300 !important;
  }
}
