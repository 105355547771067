@import '~styles/combined-variables';

.ChartNavbar {
  &-navigation {
    display: flex;
    .nav.nav-tabs .nav-item {
      padding: 0;
    }
    .nav.nav-tabs .nav-item:first-child .ChartNavbar-link {
      padding-left: 0;
    }
    .nav.nav-tabs .nav-item:last-child .ChartNavbar-link {
      padding-right: 0;
      border-right: none;
    }
    .nav.nav-tabs .nav-item .nav-link {
      margin-right: 0;
      &.active {
        font-weight: 400;
      }
    }
  }

  &-tabs {
    flex: 1;
  }

  &-link {
    border-right: 1px solid $gray-300;
    padding: 0 12px 0 12px;
    &--truncate {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-value {
    line-height: 24px;
    height: 30px;
  }

  &-label {
    line-height: 12px;
    height: 20px;
  }
}
