.AdvertorialModal.modal-dialog {
  display: flex;
  max-width: 90vw;
  width: 90vw;
  height: 90vh;
  .modal-content {
    iframe {
      flex: 1;
      width: 100%;
      border: 1px solid #ccc;
    }
  }
}
