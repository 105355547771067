.btn {
  font-weight: 600;
  padding: 0.375rem 0.625rem;

  &.disabled,
  &:disabled {
    cursor: default;
    pointer-events:none;
  }
}

.btn-primary {
  border-color:rgba(0,0,0,.15);
}

.btn-secondary {
  background-color: $white;
  border-color: $gray-300;
  color: $gray-800;
  box-shadow: 0 1px 0 rgba(0,0,0,.05);

  &:hover {
    background-color: $gray-200;
    border-color: $gray-300;
    color: $gray-800;
  }

  &:focus {
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    opacity: 1;
    color: lighten($gray-800, 45%);
    border-color: $gray-300;
    background-color: $gray-000;
  }
}

.btn-outline-secondary {
  background-color: transparent;
  border-color: $gray-300;
  color: $gray-800;

  &.disabled,
  &:disabled {
    opacity: 1;
    color: lighten($gray-800, 45%);
    border-color: $gray-300;
    background-color: transparent;
  }

  &:hover {
    border-color: $gray-300;
    background-color: $gray-000;
    color: $gray-800;
  }

  &:not(:disabled):not(.disabled):active {
    border-color: $gray-300;
    color: $gray-800;
  }
}

.btn-group .btn-outline-secondary {
  &:not(:disabled):not(.disabled).active {
    color: $primary;
    border-color: $gray-300;
    box-shadow: 4px 4px 4px rgba(0,0,0,.05) inset;
  }

  &.active.disabled {
    background-color: $gray-100;
    color: lighten($primary, 25%);
    box-shadow: 4px 4px 4px rgba(0,0,0,.03) inset;
  }
}

.dropdown-toggle:after {
  margin-left: 0.5rem;
  vertical-align: 0.125rem;
}
