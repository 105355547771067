.FormColorInput {
  &-textInput.form-control {
    flex: 0 0 auto !important;
    width: 6rem !important;
  }
  &-colorPreview {
    flex: 0 0 auto !important;
    margin-left: 1rem !important;
    margin-top: 1px;
    padding: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    border: none;
    box-shadow: 0px 1px 2px 2px rgba(0,0,0,0.1);
  }
  .input-group-append {
    margin-right: -1px;
  }
}
