@import "~styles/combined-variables";

.LabelWithSubtext {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.LabelWithSubtext-subtext {
    color: $gray-400;
}
