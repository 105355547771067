@import 'ReactTabs';
@import 'FilterGroup';
@import 'Sidebar';
@import 'Navbar';
@import 'Buttons';
@import 'Badge';
@import 'Card';
@import 'Tooltip';
@import 'ChartNavbar';

// Use on flex child to fill up remaining whitespace
.flex-grow {
  flex: 1 0 auto;
}

.flex-fit {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
}

.nav-link {
  cursor: pointer;
}

.UpdatedOn-author {
  color: $gray-400;
  font-size: 0.8125rem;
}

.dropdown-menu, .popover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px -2px, rgba(0, 0, 0, 0.35) 0px 0px 1px;
  border: none;
  padding: 0.375rem 0;
  min-width: 10rem;
}

.dropdown-item {
  padding: 0.375rem 1rem;
  border-bottom: none;
  font-size: 0.875rem;
}

.loadable {
  transition: opacity 0.1s linear;
  opacity: 1;
  &--loading {
    opacity: 0.5;
  }
}

// helper for truncatable spans in react table cell
.with-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.input-group-addon {
  background-color: $gray-000;
  font-size: 0.75rem;
  line-height: 1.6;
  color: $gray-600;
  font-weight: 500;
}

.overflow-y-auto {
  overflow-y: auto;
}

.pt-01 {
  padding-top: 0.1rem;
}

.gray-600 {
  color: $gray-600;
}

.form-max-width {
  max-width: 50rem;
}

.cursor-pointer {
  cursor: pointer;
}

.outline-0 {
  outline: none;
}

.no-text-decoration {
  text-decoration: none !important;
}

// Redifine scrollbars so mac option 'show scroll bars' couldn't hide them
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
  background-color: white;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0,0,0,.1);
  box-shadow: 0 0 1px rgba(255,255,255,.5);
}

div::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 3px;
}

input.hide-number-spin::-webkit-outer-spin-button,
input.hide-number-spin::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.modal-shadow {
  box-shadow: 0 0px 12px rgba(0, 0, 0, .26);
}
